/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// custom logo font
@import url('https://fonts.googleapis.com/css2?family=Encode+Sans+Condensed:wght@400;600;700;800&display=swap');


// bengali font 
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Bengali&display=swap');


// custom font mixing
@mixin heading1() {
    font-size: 18px;
    font-weight: 700;
    
}
@mixin heading2() {
    font-size: 16px;
    font-weight: bold;
    
}
@mixin heading3() {
    font-size: 14px;
    font-weight: bold;
    
}
@mixin body1() {
    font-size: 14px;
    font-weight: normal;
    
}
@mixin body2 {
    font-size: 12px;
    font-weight: normal;
    
}

*{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue','Noto Sans Bengali', sans-serif;
    caret-color: auto;
    .swal2-title{
        font-size: 20px !important;
    }
}
.alertBtn{
    width: 80px;
    height: 50px;
    margin: 0.5rem;
    background-color: var(--bd-green-light);
    &:last-of-type{
        background-color: var(--bd-red-light);
    }
}

.native-input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}